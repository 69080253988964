<template>
    <div class="form-input-generic">
        <img v-if="image" class="icon" :class="imagestyle" :src="image" alt="image" />
        <input
            v-bind="$attrs"
            ref="input"
            class="form-input-generic__input"
            :disabled="disabled"
            :class="[
                { invalid },
                bgcolor === 'light' ? 'bg-[#F8F7FC]' : 'bg-[#ece9f2]',
                image ? 'indent-7' : '',
            ]"
            :value="modelValue"
            :type="type"
            @click="(e) => $emit('click', e)"
            @focus="(e) => $emit('focus', e)"
            @input="inputEmitter"
            @blur="(e) => $emit('blur', e)"
        />
        <div v-if="invalid && typeof invalid === 'string'" class="form-input-generic__error">
            <img :src="$assets.red.formError" alt="icon" />
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InputGeneric',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        modelValue: {
            type: String,
        },
        invalid: {
            type: [Boolean, String],
            default: false,
        },
        bgcolor: {
            type: String,
        },
        image: {
            type: String,
        },
        imagestyle: {
            type: String,
            default: 'top-3',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['click', 'focus', 'blur', 'update:modelValue'],
    methods: {
        focus() {
            ;(this.$refs.input as HTMLInputElement).focus()
        },
        blur() {
            ;(this.$refs.input as HTMLInputElement).blur()
        },
        select() {
            ;(this.$refs.input as HTMLInputElement).select()
        },
        setValue(value: string) {
            ;(this.$refs.input as HTMLInputElement).value = value
        },
        inputEmitter(e: Event) {
            this.$emit('update:modelValue', (e.target as HTMLInputElement).value)
        },
    },
})
</script>

<style lang="postcss" scoped>
.form-input-generic {
    @apply relative text-lg;
    .icon {
        @apply absolute left-3;
    }
    &__input {
        @apply block w-full rounded-xl border-transparent bg-gray-100 py-3 text-sm text-gray-800 placeholder-gray-400 ring-site-primary-hover-light focus:border-site-primary-hover-light focus:bg-white focus:text-site-primary focus:ring-1 focus:ring-site-primary-hover-light lg:hover:ring-1;
        min-width: theme('spacing.8');
        &.invalid {
            @apply border-red-500 ring-1 ring-red-500;
        }
    }
    &__error {
        @apply mt-2 flex items-center space-x-1.5 text-sm text-red-500;
        img {
            @apply w-4;
        }
    }
}
</style>
