<template>
    <div
        v-if="isMounted && currentNotification"
        class="header-notifications"
        :class="currentNotification.type"
    >
        <div class="header-notifications__container">
            <div class="header-notifications__message">
                <img :src="$assets.white[currentNotification.type]" alt="icon" />
                <p v-html="currentNotification.text"></p>
            </div>
            <button type="button" class="header-notifications__button" @click="dismiss">
                <img :src="$assets.white.trash" alt="icon" />
                <span class="hidden md:block">{{ currentNotification.dismissButtonText }}</span>
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

const { setSiteNotification, getNotification } = useRootStore()

watch(getNotification, (notification) => {
    if (notification) {
        createNotification(notification)
    }
})

export type NotificationInit = {
    text: string
    dismissButtonText: string
    type: 'warning' | 'error' | 'success' | 'help'
    duration: number
    fromServer?: boolean
    onClose?: () => void
}

export type NotificationOptions = {
    text: string
    dismissButtonText?: string
    type?: 'warning' | 'error' | 'success' | 'help'
    duration?: number
    fromServer?: boolean
    onClose?: () => void
}

const isMounted = ref(false)

let notificationTimer = null as null | NodeJS.Timeout

const defaultOptions = {
    duration: 6000,
    type: 'success',
    dismissButtonText: 'Cerrar',
    fromServer: false,
}

const notificationStack = ref([]) as Ref<NotificationInit[]>

const currentNotification = ref(null) as Ref<NotificationInit | null>

const emits = defineEmits(['notifications'])

watch(currentNotification, (notification) => {
    emits('notifications', !!notification)
})

const createNotification = (config: NotificationOptions) => {
    const notification = {
        ...defaultOptions,
        ...config,
    } as NotificationInit

    if (currentNotification.value && notificationTimer) {
        if (currentNotification.value.text !== notification.text) {
            notificationStack.value = [...notificationStack.value, notification]
        }
    } else {
        mountNotification(notification, true)
    }
}

const mountNotification = (notification: NotificationInit, handleClasses: boolean) => {
    currentNotification.value = notification

    isMounted.value = true

    if (handleClasses) {
        // prevent scroll
        document.body.classList.add('add-notifications-height')
    }

    notificationTimer = setTimeout(() => dismiss(), currentNotification.value.duration)
}

const dismiss = () => {
    setSiteNotification(null)

    currentNotification.value?.onClose?.()

    if (notificationTimer) {
        clearTimeout(notificationTimer)

        notificationTimer = null
    }

    if (notificationStack.value.length > 0) {
        const nextNotification = notificationStack.value.pop() as NotificationInit
        mountNotification(nextNotification, false)
    } else {
        //prevent scroll

        document.body.classList.remove('add-notifications-height')

        setTimeout(() => {
            isMounted.value = false

            currentNotification.value = null
        }, 350)
    }
}
</script>

<style lang="postcss" scoped>
.header-notifications {
    &__container {
        @apply container flex h-14 items-center justify-between space-x-5 overflow-hidden lg:h-12 lg:justify-center lg:space-x-10;
    }
    &__message {
        @apply flex items-center space-x-4 opacity-90;
        img {
            @apply h-6 w-6 animate-pulse;
        }
        p {
            @apply text-sm leading-4 text-white;
        }
    }
    &__button {
        @apply h-8 w-8 flex-none rounded-lg border border-white border-opacity-40 bg-black bg-opacity-10 text-white md:flex md:w-auto md:items-center md:space-x-1 md:px-3 lg:transition-colors lg:hover:bg-opacity-30;

        img {
            @apply mx-auto h-4 w-4 opacity-90;
        }
        span {
            @apply text-xs font-medium opacity-90;
        }
    }

    &.warning {
        @apply bg-[#FF9900];
    }
    &.success {
        @apply bg-[#38761D];
    }
    &.error {
        @apply bg-[#CC0000];
    }
    &.help {
        @apply bg-[#277FE6];
    }
}
</style>

<style lang="postcss">
.add-notifications-height {
    --notification-height: theme('spacing.14');
}
@screen lg {
    .add-notifications-height {
        --notification-height: theme('spacing.12');
    }
}
</style>
